import { Component, Input, OnInit } from '@angular/core';
import { HoldsAndCompartmentsCalc, HoldsAndCompartments } from 'src/app/weight-balance-data/weight-balance';

@Component({
  selector: 'dearload-summary-table',
  templateUrl: './dearload-summary-table.component.html',
  styleUrls: ['./dearload-summary-table.component.less']
})
export class DearloadSummaryTableComponent implements OnInit {

  @Input() holds: HoldsAndCompartments;
  @Input() calculationHold: Array<HoldsAndCompartmentsCalc>;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Функция, которая вычисляет общий вес в секции
   * @param section имя секции
   */
  getLoadWeight(section: string): number {
    return this.calculationHold
      .filter(el => el.name === section)
      .reduce((acc, el) => {
        return acc += el.weight + el.uldWeight;
      }, 0);
  }

  /**
   * Функция получения веса загрузки по типу и багажнику
   * @param type тип загрузки
   * @param section багажник
   */
  getLoadCompartment(section: string, type: string | null = null): number {
    if (type) {
      return this.calculationHold
        .filter(
          (el) =>
            el.name &&
            el.name[0] === section &&
            el.type[0] &&
            el.type[0].toLocaleUpperCase() === type.toLocaleUpperCase()
        )
        .reduce((acc, el) => {
          return (acc += el.weight + el.uldWeight);
        }, 0);
    } else {
      return this.calculationHold
        .filter(el => el.name && el.name[0] === section)
        .reduce((acc, el) => acc += el.weight + el.uldWeight, 0);
    }
  }

}
