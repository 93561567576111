const BULK = 'BULK';
enum Orientation {
  LR = 'LR',
  FB = 'FB'
}
enum Position {
  L = 'L',
  R = 'R'
}
const LengthUnits = {
  m: {name: 'm', size: 100},
  sm: {name: 'sm', size: 1},
  inches: {name: 'inches', size: 2.54}
};

export class CompartmentsMap {
  constructor() {
    this.width = null;
    this.height = null;
    this.proportions = null;
    this.trunkMap = [];
    this.darggableCargo = null;
    this.targetBay = null;
    this.filterType = '';
  }
  width: number;
  height: number;
  proportions: number;
  loading?: boolean;
  // груз, который тащим мышью
  darggableCargo?;
  // багажник, на который притащили
  targetBay?: any;
  filterType?: string;
  // map or table
  locationEvent?: string;
  trunkMap: Array<{
    width: number;
    height: number;
    doors: Array<{
      width: number;
      right: number;
    }>;
    bays: Array<{
      name: string;
      uldTypes: Array<any>;
      category: string;
      maxWeight: number;
      maxVolume: number;
      classes: Array<string>;
      styles: {
        width: number;
        height: number;
        right: number;
      };
    }>;
  }>;
}

export function generateMap({bayMap, holdsAndCompartments, uldTypes, uldBaseSizes, untils}) {
  const compartmentsMap = new CompartmentsMap();

  const sizes = getMapSizeAndProportions(bayMap, holdsAndCompartments);
  compartmentsMap.width = sizes.length;
  compartmentsMap.proportions =  sizes.proportions;

  compartmentsMap.trunkMap = generateBayMap(holdsAndCompartments, compartmentsMap, uldTypes, uldBaseSizes, untils);

  console.log(compartmentsMap);

  return compartmentsMap;
}

export function getMapSizeAndProportions(bayMap, holdsAndCompartments) {
  const parent = (bayMap.parentElement) ? bayMap.parentElement?.offsetWidth - 30 : 1000;

  let length = 0;
  if (holdsAndCompartments.acSize && holdsAndCompartments.acSize.length > 0) {
    holdsAndCompartments.acSize.forEach(size => {
      length += size.finish - size.start;
    });
    const proportions =  +(parent / length).toFixed(2);
    return { length, proportions };
  } else {
    return { length: null, proportions: null };
  }
}

function generateBayMap(holdsAndCompartments, compartmentsMap, uldTypes, uldBaseSizes, untils) {
  const trunkMap = [];

  if (holdsAndCompartments.acSize && holdsAndCompartments.acSize.length > 0) {
    holdsAndCompartments.acSize.forEach(compartment => {
      const data = {
        width: compartment.finish - compartment.start,
        height: 0,
        doors: [],
        bays: [],
      };
      // добавляем двери
      holdsAndCompartments.doors.forEach(door => {
        if (door.from >= compartment.start && door.to < compartment.finish) {
          data.doors.push({
            width: door.to - door.from,
            right: door.from  - compartment.start
          });
        }
      });

      // добавляем контейнеры
      holdsAndCompartments.aft.forEach(bay => {
        const bayItem = generateContainerForMap(bay, compartment, compartmentsMap, uldTypes, uldBaseSizes, untils);
        if (bayItem) {
          data.bays.push(bayItem);
        }
        if (bay.bays && bay.bays.length > 0) {
          bay.bays.forEach(bayChild => {
            const bayChildItem = generateContainerForMap(bayChild, compartment, compartmentsMap, uldTypes, uldBaseSizes, untils);
            if (bayChildItem) {
              data.bays.push(bayChildItem);
            }
          });
        }
      });
      holdsAndCompartments.fwd.forEach(bay => {
        const bayItem = generateContainerForMap(bay, compartment, compartmentsMap, uldTypes, uldBaseSizes, untils);
        if (bayItem) {
          data.bays.push(bayItem);
        }
        if (bay.bays && bay.bays.length > 0) {
          bay.bays.forEach(bayChild => {
            const bayChildItem = generateContainerForMap(bayChild, compartment, compartmentsMap, uldTypes, uldBaseSizes, untils);
            if (bayChildItem) {
              data.bays.push(bayChildItem);
            }
          });
        }
      });

      if (!compartmentsMap.height || compartmentsMap.height < 0) {
        compartmentsMap.height = compartmentsMap.width * 0.15;
      }

      data.bays.forEach(bay => {
        const interception = holdsAndCompartments.interceptions.find(el => el.cmp1 === bay.name);
        const pos = bay.name.slice(-1).toUpperCase();
        if (bay.category.toUpperCase() === BULK) {
          bay.styles.height = compartmentsMap.height;
        } else if (interception && pos !== Position.R && pos !== Position.L) {
          if (bay.styles.height >= compartmentsMap.height) {
            bay.styles.height = bay.styles.height - 0.22 * compartmentsMap.height;
          }
          bay.styles.bottom = compartmentsMap.height / 2 - bay.styles.height / 2;
          bay.styles.index = 10;
        }
      });
      trunkMap.push(data);
    });
  }

  return trunkMap;
}

function generateContainerForMap(bay, compartment, compartmentsMap, uldTypes, uldBaseSizes, untils) {
  if (bay.type.toUpperCase() === BULK && bay.from >= compartment.start && bay.to <= compartment.finish) {
    return {
      name: bay.name,
      uldTypes: [bay.type.toUpperCase()],
      category: bay.type,
      maxWeight: bay.maxWeight,
      maxVolume: bay.volume,
      group: bay.group,
      styles: {
        width: bay.to - bay.from,
        height: 3.18 * LengthUnits[untils.length].size,
        right: bay.from - compartment.start,
      }
    };
  } else if (bay.centroid >= compartment.start && bay.centroid <= compartment.finish) {
    if (bay.uldTypeIds && bay.uldTypeIds.length > 0) {
      const uld = uldTypes.find(el => el.id === bay.uldTypeIds[0]);
      const baseSize = uldBaseSizes.find(el => el.id === uld.baseSizeId);
      const baseLength = baseSize.length / LengthUnits[untils.length].size;
      const baseWidth = baseSize.width / LengthUnits[untils.length].size;

      const bayItem: any = {
        name: bay.name,
        uldTypes: bay.uldTypes,
        category: bay.type.toUpperCase() === BULK ? bay.type : uld.category,
        maxWeight: bay.maxWeight,
        maxVolume: bay.volume,
        group: bay.group,
        classes: [],
        styles: {
          width: bay.orientation === Orientation.LR ? baseWidth : baseLength,
          height: bay.orientation === Orientation.LR ? baseLength : baseWidth,
          right: (bay.centroid - (bay.orientation === Orientation.LR ? baseWidth / 2 : baseLength / 2) - compartment.start),
        }
      };
      if (bay.name.slice(-1).toUpperCase() === Position.L) {
        bayItem.styles.top = 0;
        bayItem.classes.push('left');
        if (baseLength * 2 > compartmentsMap.height) {
          compartmentsMap.height = baseLength * 2;
        }
      } else if (bay.name.slice(-1).toUpperCase() === Position.R) {
        bayItem.styles.bottom = 0;
        bayItem.classes.push('right');
        if (baseLength * 2 > compartmentsMap.height) {
          compartmentsMap.height = baseLength * 2;
        }
      } else if (baseLength > compartmentsMap.height) {
        compartmentsMap.height = baseLength;
      }
      return bayItem;
    }
  } else {
    return false;
  }
}
