import { Injectable } from '@angular/core';
import { ModuleName } from './global-settings';

@Injectable()
export class GlobalI18n {
    constructor(){
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'messageSave',
                        $localize`:@@cmn.app.message.messageSave:Message save`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'messageSent',
                        $localize`:@@cmn.app.message.messageSent:Message sent`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'sendToArchive',
                        $localize`:@@cmn.app.message.sendToArchive:Send to archive?`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'notSendToArchive',
                        $localize`:@@cmn.app.message.notSendToArchive:Not send to archive?`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'restoreFromArchive',
                        $localize`:@@cmn.app.message.restoreFromArchive:Restore message from archive?`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'splitMessage',
                        $localize`:@@cmn.app.message.splitMessage:The message length is longer than allowed. The message will be split into several parts. Proceed?`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'textLong',
                        $localize`:@@cmn.app.message.textLong:Message is too long, maximum number of characters:`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'invalidDate',
                        $localize`:@@cmn.app.message.invalidDate:The message was created more than 1 hour ago, check the relevance of the data sent`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'deleteItem',
                        $localize`:@@cmn.app.message.deleteItem:Delete the record?`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'textEmpty',
                        $localize`:@@cmn.app.message.textEmpty:Enter your message`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'textBadCharacters',
                        $localize`:@@cmn.app.message.textBadCharacters:The message text contains prohibited characters:`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'textBadSequences',
                        $localize`:@@cmn.app.message.textBadSequences:The message text contains prohibited character sequences:`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'errorTextValidation',
                        $localize`:@@cmn.app.message.errorTextValidation:There are errors in the message text.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'textLineLong',
                        $localize`:@@cmn.app.message.textLineLong:The line is too long, please edit it.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'fillRequiredFields',
                        $localize`:@@cmn.app.message.fillRequiredFields:Fill in all required fields`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'deleteElement',
                        $localize`:@@cmn.app.message.deleteElement:Delete element?`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'cantRemoveLastItem',
                        $localize`:@@cmn.app.message.cantRemoveLastItem:You cannot remove the last item`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'dateStartGreatDataFinish',
                        $localize`:@@cmn.app.message.dateStartGreatDataFinish:Date range start cannot be greater than Date range finish`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'templateMustContainOneField',
                        $localize`:@@cmn.app.message.templateMustContainOneField:The template must contain at least one field`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'longRecipientList',
                        $localize`:@@cmn.app.message.longRecipientList:The list of recipients cannot be more than 56.\nCreate multiple messages or shorten your recipient list.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'addressesContainRecipientsNotAcceptCyrillic',
                        $localize`:@@cmn.app.message.addressesContainRecipientsNotAcceptCyrillic:Addresses contain recipients who do not receive messages in Cyrillic.\nChange the message language for the channel or edit the recipient addresses.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'textNotAcceptCyrillic',
                        $localize`:@@cmn.app.message.textNotAcceptCyrillic:The language of the message is "Latin", while the text contains Cyrillic characters.\nChange the message language for the channel or edit message text.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'textNotAcceptCyrillicForSita',
                        $localize`:@@cmn.app.message.textNotAcceptCyrillicForSita:The text sent via the Sitatex channel must be in English only.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'addAFTNAddressLength',
                        $localize`:@@cmn.app.message.addAFTNAddressLength:AFTN address must be 8 characters`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'addAFTNAddressBadChartec',
                        $localize`:@@cmn.app.message.addAFTNAddressBadChartec:Invalid characters found in the address`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'addSITAAddressLength',
                        $localize`:@@cmn.app.message.addSITAAddressLength:SITATEX address must be 7 characters`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'addSITAAddressBadChartec',
                        $localize`:@@cmn.app.message.addSITAAddressBadChartec:Invalid characters found in the address`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'addEmailAddressBadChartec',
                        $localize`:@@cmn.app.message.addEmailAddressBadChartec:Email address is incorrect`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'containedInLines',
                        $localize`:@@cmn.app.message.containedInLines:message type is contained in lines`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'firstRule',
                        $localize`:@@cmn.app.message.firstRule:The rule with the shortest time will be triggered first by placing the telegram in the archive.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'emptyConditions',
                        $localize`:@@cmn.app.message.emptyConditions:Add at least one condition.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'emptySchedule',
                        $localize`:@@cmn.app.message.emptySchedule:Add at least one work schedule.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'active',
                        $localize`:@@cmn.app.message.active:Active`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'notActive',
                        $localize`:@@cmn.app.message.notActive:Not active`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'allOnFilter',
                        $localize`:@@cmn.app.message.allOnFilter:On filter:`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'allOnFolder',
                        $localize`:@@cmn.app.message.allOnFolder:All:`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'directionIncoming',
                        $localize`:@@cmn.app.message.directionIncoming:Incoming`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'directionOutgoing',
                        $localize`:@@cmn.app.message.directionOutgoing:Outgoing`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'directionIncomingOutgoing',
                        $localize`:@@cmn.app.message.directionIncomingOutgoing:Incoming and outgoing`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'numberOfFiltersExceeded',
                        $localize`:@@cmn.app.message.numberOfFiltersExceeded:The number of saved filters has been exceeded. Remove one of the filters to create a new one.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'newMessageReadOnlyFolder',
                        $localize`:@@cmn.app.message.newMessageReadOnlyFolder:Unable to create a new message in a read-only folder. Please change the folder and try again.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'newMessagePublicFolder',
                        $localize`:@@cmn.app.message.newMessagePublicFolder:Unable to create a new message in public folder. Please change the folder and try again.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'newMessageDraftFolder',
                        $localize`:@@cmn.app.message.newMessageDraftFolder:Unable to create a new message in draft folder. Please change the folder and try again.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'deleteTemplate',
                        $localize`:@@cmn.app.message.deleteTemplate:Delete template?`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'readAll',
                        $localize`:@@cmn.app.message.readAll:Mark all messages in a folder as read? The procedure may take several minutes.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'readAllOk',
                        $localize`:@@cmn.app.message.readAllOk:Request has been sent. The procedure may take several minutes.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'deleteFilter',
                        $localize`:@@cmn.app.message.deleteFilter:Delete filter?`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'deleteFilter',
                        $localize`:@@cmn.app.message.deleteFilter:Delete filter`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'deleteFilter',
                        $localize`:@@cmn.app.message.deleteFilter:Delete filter`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'translateOnlyAftn',
                        $localize`:@@cmn.app.message.translateOnlyAftn:Translation of the telegram into another language is only available when sending via the AFTN channel: specify the addresses of the AFTN recipients.`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'saveDraft',
                        $localize`:@@cmn.app.message.saveDraft:Save message to drafts?`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'replaceInTheTemplate',
                        $localize`:@@cmn.app.message.replaceInTheTemplate:Are you sure you want to replace the information in the template?`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'replacedIn',
                        $localize`:@@cmn.app.message.replacedIn:Replaced in`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'replacedIn',
                        $localize`:@@cmn.app.message.replaced:Replaced`)
        );
        this.messages.push(
            new Message(ModuleName.ComMan,
                        'templates',
                        $localize`:@@cmn.app.message.templates:templates`)
        );

        // SlotCoord
        this.messages.push(
            new Message(ModuleName.SlotCoord,
                        'period',
                        $localize`:@@slc.app.message.period:Period`)
        );
        this.messages.push(
            new Message(ModuleName.SlotCoord,
                        'setDatesPeriod',
                        $localize`:@@slc.app.message.setDatesPeriod:Please set start and finish dates in the period`)
        );
        this.messages.push(
            new Message(ModuleName.SlotCoord,
                        'beginGraterFinish',
                        $localize`:@@slc.app.message.beginGraterFinish:The beginning of the period cannot be greater than the end`)
        );
        this.messages.push(
            new Message(ModuleName.SlotCoord,
                        'periodOutsideSeason',
                        $localize`:@@slc.app.message.periodOutsideSeason:The period of the flight is outside the boundaries of the selected season`)
        );
        this.messages.push(
            new Message(ModuleName.SlotCoord,
                        'periodFrequency',
                        $localize`:@@slc.app.message.periodFrequency:Fill in the frequency in the period`)
        );

        // MasterData
        this.messages.push(
            new Message(ModuleName.MasterData,
                        'finishGraterStart',
                        $localize`:@@slc.app.message.finishGraterStart:Date range start cannot be greater than Date range finish`)
        );
        this.messages.push(
            new Message(ModuleName.MasterData,
                        'seasonDateError',
                        $localize`:@@slc.app.message.seasonDateError:Season start cannot be greater than Season finish`)
        );
        this.messages.push(
            new Message(ModuleName.MasterData,
                'deleteItem',
                $localize`:@@slc.app.message.deleteItem:Delete the record?`)
        );

        // Boarding Control
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusWaiting',
                $localize`:@@brc.app.message.statusWaiting:Ready to scan`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
            'statusCheckPassed',
            $localize`:@@brc.app.message.statusCheckPassed:Check passed`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusProcessing',
                $localize`:@@brc.app.message.statusProcessing:Boarding pass processing`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusWrong',
                $localize`:@@brc.app.message.statusWrong:Check not passed`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusWanted',
                $localize`:@@brc.app.message.statusWanted:Passenger on the wanted list`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusIncorrectDate',
                $localize`:@@brc.app.message.statusIncorrectDate:The flight date cannot be read`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusInvalidBarCode',
                $localize`:@@brc.app.message.statusInvalidBarCode:Boarding pass not recognized`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusScannerBarcodeOff',
                $localize`:@@brc.app.message.statusScannerBarcodeOff:The boarding pass scanner is not connected`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusScannerPassOff',
                $localize`:@@brc.app.message.statusScannerPassOff:Passport scanner not connected`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusWSSClosed',
                $localize`:@@brc.app.message.statusWSSClosed:The boarding pass reader app is not running`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusNewInstallationPlace',
                $localize`:@@brc.app.message.statusNewInstallationPlace:The workplace is not configured, contact the administrator`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusNoTimeEnter',
                $localize`:@@brc.app.message.statusNoTimeEnter:Entry is prohibited, flight service has not started`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusTimeToEndBoarding',
                $localize`:@@brc.app.message.statusTimeToEndBoarding:Entry is prohibited, flight service ends`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'statusFlightNotFound',
                $localize`:@@brc.app.message.statusFlightNotFound:Flight not found in schedule`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'deny',
                $localize`:@@brc.app.message.deny:Deny`)
        );
        this.messages.push(
            new Message(ModuleName.BoardingControl,
                'allow',
                $localize`:@@brc.app.message.allow:Allow`)
        );

        // Schedule
        this.messages.push(
            new Message(ModuleName.Schedule,
                        'current',
                        $localize`:@@sch.app.message.current:Current (-24h - +48h)`)
        );

        this.messages.push(
            new Message(ModuleName.Schedule,
                        'lmgPrompt',
                        $localize`:@@sch.app.message.lmgPrompt:Low mobility group`)
        );

        this.messages.push(
            new Message(ModuleName.Schedule,
                        'delInfoArrival',
                        $localize`:@@sch.app.message.delInfoArrival:No waypoint on arrival: information on arrival will be deleted.`)
        );

        this.messages.push(
            new Message(ModuleName.Schedule,
                        'delInfoDeparture',
                        $localize`:@@sch.app.message.delInfoDeparture:No waypoint for departure: departure information will be deleted.`)
        );
        this.messages.push(
            new Message(ModuleName.Schedule,
                        'statusNotTerminal',
                        $localize`:@@sch.app.message.statusNotTerminal:Wrong Terminal.`)
        );
        this.messages.push(
            new Message(ModuleName.Schedule,
                        'deleteItem',
                        $localize`:@@sch.app.message.deleteItem:Delete the record?`)
        );

        // Reports
        this.messages.push(
            new Message(ModuleName.Reports,
                'messageSent',
                $localize`:@@rep.app.message.messageSent:Message sent`)
        );
        this.messages.push(
            new Message(ModuleName.Reports,
                'deleteChart',
                $localize`:@@rep.app.message.deleteChart:Are you sure you want to delete the chart?`)
        );

        // Workflow
        this.messages.push(
            new Message(ModuleName.Workflow,
                'deleteItem',
                $localize`:@@wfl.app.message.deleteItem:Delete the record?`)
        );

        // WeightBalance
        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'deleteAhm',
              $localize`:@@wb.app.message.deleteAhm:Are you sure you want to remove AHM?`)
        );

        this.messages.push(
            new Message(ModuleName.WeightBalance,
                        'deleteItem',
                        $localize`:@@wb.app.message.deleteItem:Delete the record?`)
        );

        this.messages.push(
            new Message(ModuleName.WeightBalance,
                        'bayBlocked',
                        $localize`:@@wb.app.message.bayBlocked:blocked!`)
        );

        this.messages.push(
            new Message(ModuleName.WeightBalance,
                        'overloadUld',
                        $localize`:@@wb.app.message.overloadUld:Overload ULD`)
        );

        this.messages.push(
            new Message(ModuleName.WeightBalance,
                        'takeFlight',
                        $localize`:@@wb.app.message.takeFlight:Take a flight to calculate?`)
        );

        this.messages.push(
            new Message(ModuleName.WeightBalance,
                        'combineLoads',
                        $localize`:@@cmn.app.message.combineLoads:Combine loads?`)
        );

        this.messages.push(
            new Message(ModuleName.WeightBalance,
                        'swapLoads',
                        $localize`:@@cmn.app.message.swapLoads:Swap loads?`)
        );

        this.messages.push(
            new Message(ModuleName.WeightBalance,
                        'uploadSelected',
                        $localize`:@@cmn.app.message.uploadSelected:Upload selected?`)
        );

        this.messages.push(
            new Message(ModuleName.WeightBalance,
                        'deleteSelected',
                        $localize`:@@cmn.app.message.deleteSelected:Delete selected?`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
                      'current',
                      $localize`:@@wb.app.message.current:Current (-24h — +48h)`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'copyAhm',
              $localize`:@@wb.app.message.copyAhm:Create a copy of the AHM including all A/C registration numbers?`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'setCurrentRevision',
              $localize`:@@wb.app.message.setCurrentRevision:This revision will be used for calculations, all other AHM for this aircraft will be outdated, are you sure?`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'deleteAhmData',
              $localize`:@@wb.app.message.deleteAhmData:Are you sure you want to remove the A/C registration number?`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'enterAircraft',
              $localize`:@@wb.app.message.enterAircraft:Enter the version of the aircraft`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'enterFuelData',
              $localize`:@@wb.app.message.enterFuelData:Enter fuel data`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'fuelWeightDiscrepancy',
              $localize`:@@wb.app.message.fuelWeightDiscrepancy:Ballast fuel consumption on the flight was detected. Check the entered data.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'enterWeightData',
              $localize`:@@wb.app.message.enterWeightData:Enter weight data (MTOW / MLW / MZFW)`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'enterCrewDowDoi',
              $localize`:@@wb.app.message.enterCrewDowDoi:Enter crew composition and DOW/DOI values`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'payloadWarning',
              $localize`:@@wb.app.message.payloadWarning:The payload: the data entered does not match the expected weight.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'seatsWarning',
              $localize`:@@wb.app.message.seatsWarning:The zone seating: the data entered does not match the expected seating.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'noBaseAirport',
              $localize`:@@wb.app.message.noBaseAirport:There is no base airport.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'missingRequiredFields',
              $localize`:@@wb.app.message.missingRequiredFields:Required fields are missing.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'telegramSent',
              $localize`:@@wb.app.message.telegramSent:Telegram sent.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'reloadAhmData',
              $localize`:@@wb.app.message.reloadAhmData:All data will be cleared and replaced with default values, are you sure?`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'reloadWeights',
              $localize`:@@wb.app.message.reloadWeights:Weights will be cleared and replaced with default values, are you sure?`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'enterVersionAircraft',
              $localize`:@@wb.app.message.enterVersionAircraft:Choose cabin version.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'enterCrewComposition',
              $localize`:@@wb.app.message.enterCrewComposition:Enter crew composition and DOW/DOI values.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'warningPayload',
              $localize`:@@wb.app.message.warningPayload:The payload: the data entered does not match the expected weight.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'summaryWeightCompartment',
              $localize`:@@wb.app.message.summaryWeightCompartment:The summary weight of the compartments`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'cannotExceedValue',
              $localize`:@@wb.app.message.summaryWeightCompartmentExceedValue:cannot exceed the value`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'warningZoneSeating',
              $localize`:@@wb.app.message.warningZoneSeating:The zone seating: the data entered does not match the expected seating.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'continue',
              $localize`:@@wb.app.message.continue:Continue?`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'warningRoute',
              $localize`:@@wb.app.message.warningRoute:Route must contain at least 2 points.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'Last',
              $localize`:@@wb.app.message.Last:Last`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'maxTow',
              $localize`:@@wb.app.message.maxTow:Maximum weight exceeded: TOW (take off weight).`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'compartmentsOverloaded',
              $localize`:@@wb.app.message.compartmentsOverloaded:Compartments is overloaded. Check the entered data.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'noLuggageCompartmentsSelected',
              $localize`:@@wb.app.message.noLuggageCompartmentsSelected:No baggage compartments selected. Check the entered data.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'maxValueCabinSectionExceeded',
              $localize`:@@wb.app.message.maxValueCabinSectionExceeded:Max value of cabin section exceeded. Check the entered data.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'zoneSeatindError',
              $localize`:@@wb.app.message.zoneSeatindError:The zone seating: the data entered does not match the expected seating.`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'totalPassengersMoreMax',
              $localize`:@@wb.app.message.zoneSeatindError:The total number of passengers is more than the maximum allowed`)
        );

        this.messages.push(
          new Message(ModuleName.WeightBalance,
              'maxLw',
              $localize`:@@wb.app.message.maxLaw:Maximum weight exceeded: LAW (landing weight actual).`)
        );

        // Lost Found

        this.messages.push(
            new Message(ModuleName.LostFound,
                'baggageTagError',
                $localize`:@@laf.app.message.baggageTagError:Baggage tag must contain airline code`)
          );
        this.messages.push(
          new Message(ModuleName.LostFound,
              'baggageStatusCodeError',
              $localize`:@@laf.app.message.baggageStatusCodeError:Enter the search result code for baggage`)
        );
    }

    private messages: Array<Message> = [];
    /**
     * Функция получения сообщения для модуля
     * @module {Module} Наименование модуля из enum'a
     * @id {string} Идентификатор сообщения
     * @return {string} Сообщения на текущем языке системы
     */
    getMessage(module: ModuleName, id: string): string {
        let messageText = '';
        for (let i = 0; i < this.messages.length; i++) {
            if (this.messages[i].module === module && this.messages[i].messageId === id) {
                messageText = this.messages[i].message;
                break;
            }
        }
        return messageText;
    }
}

export class Message {
    constructor(module: ModuleName, messageId: string, message: string) {
        this.module = module;
        this.messageId = messageId;
        this.message = message;
    }

    // Модуль в котором используется сообщение
    module: ModuleName;
    // Уникальный идентификатор сообщения
    messageId: string;
    // Переменная сообщения, содержит текст на языке выбранном в системе
    message: string;
}
