export class FilterParams {
  constructor() {
    this.name = [];
    this.tag = [];
    this.passenger = null;
    this.pnr = null;
    this.airport = [];
    this.archived = false;
    this.baggageType = [];
    this.baggageColor = [];
    this.baggageMaterial = [];
    this.baggageExternalElements = [];
    this.airline = [];
    this.status = null;
    this.start = null;
    this.finish = null;

    this.type = [];
    this.archived = false;
    this.statusFlag = '';
    this.baggageTypeFlag = '';
    this.baggageColorFlag = '';
    this.baggageMaterialFlag = '';
    this.baggageExternalElementsFlag = '';
  }
  start: Date;
  finish: Date;
  status: string;
  statusFlag: string;
  type: Array<number>;
  mark: string;
  name: Array<string>;
  tag: Array<string>;
  passenger: string;
  pnr: string;
  airport: Array<number>;
  airline: Array<number>;
  baggageTypeFlag: string;
  baggageType: Array<number>;
  baggageColorFlag: string;
  baggageColor: Array<number>;
  baggageMaterialFlag: string;
  baggageMaterial: Array<number>;
  baggageExternalElementsFlag: string;
  baggageExternalElements: Array<number>;
  archived: boolean;
}

export class Statement {
  constructor(obj?) {
    obj && Object.assign(this, obj);
    this.dtInsert = new Date(this.dtInsert).getFullYear() > 2000 ? this.dtInsert : null;
  }
  agent: string;
  airport: string;
  statementId: string;
  baggageId: string;
  mark: string;
  arrival: {
    flight: string;
    dt: Date;
  };
  statementName: string;
  dtInsert: Date;
  passenger: string;
  passengerStatus: string;
  baggage: {
    reason: string;
    tag: string;
    status: string;
    statusId: number;
    wt: string;
  };
  baggageType: {
    code: string;
    name: string;
  };
  color: {
    code: string;
    name: string;
  };
  material: {
    code: string;
    name: string;
  };
  externalElements: Array<number>;
}


export class StatementData {
  constructor(obj?) {
    if (obj) {
      obj && Object.assign(this, obj);
      let dueDate = this.passenger.additionalInfo.temporary.dueDate;
      this.passenger.additionalInfo.temporary.dueDate = new Date(dueDate).getFullYear() > 2000 ? dueDate : null;
      if (!this.passenger.additionalInfo.passengers) {
        this.passenger.additionalInfo.passengers = [];
        this.passenger.additionalInfo.passengers.push(new Passenger);
        this.passenger.additionalInfo.passengers.push(new Passenger);
      }
    } else {
      this.id = null;
      this.airportId = null;
      this.airlineId = null;
      this.baggageId = '';
      this.baggage = [new Baggage()];
      this.mark = '';
      this.passenger = {
        passenger: new Passenger,
        amount: null,
        pnr: '',
        ticket: '',
        phone1: '',
        phone2: '',
        email: '',
        address: '',
        notification: ['Email', 'SMS'],
        baggage: {
          amount: null,
          weight: null,
        },
        additionalInfo: {
          class: '',
          language: '',
          amount: null,
          gender: '',
          overknightKit: {
            male: null,
            female: null,
          },
          ffp: '',
          status: '',
          temporary: {
            address: '',
            phone: '',
            dueDate: null,
          },
          passengers: [new Passenger, new Passenger]
        }
      };
      this.route = {
        full: {
          flights: []
        }
      };
      this.messages = {
        incoming: '',
        outgoing: '',
      };
    }
  }

  id: string;
  baggageId: string;
  airportId: number;
  airport: string;
  airlineId: number;
  airline: string;
  archived: boolean;
  name: string;
  typeId: number;
  type: string;
  mobile: boolean;
  mark: string;
  dtInsert: Date;
  route: {
    full: {
      flights: Array<Flight>;
    }
  };
  passenger: {
    passenger: Passenger;
    amount: number;
    pnr: string;
    ticket: string;
    phone1: string;
    phone2: string;
    email: string;
    address: string;
    notification: Array<string>;
    baggage: {
      amount: number;
      weight: number;
    };
    additionalInfo: {
      class: string;
      language: string;
      amount: number;
      gender: string;
      overknightKit: {
        male: number;
        female: number;
      };
      ffp: string;
      status: string;
      temporary: {
        address: string;
        phone: string;
        dueDate: Date;
      };
      passengers: Array<Passenger>;
    }
  };
  baggage: Array<Baggage>;
  messages: {
    incoming: string;
    outgoing: string;
  };
  username: string;
  commentary: string;

  get passengerRoute() {
    let route = '';
    let last = '';
    if (this.route.full.flights && this.route.full.flights.length) {
      route += this.route.full.flights[0].departure + ' - ' + this.route.full.flights[0].arrival;
      last = this.route.full.flights[0].arrival;
    }
    for (let i = 1; i < this.route.full.flights.length; i++) {
      let next = this.route.full.flights.find(el => el.departure === last);
      if (next) {
        route += ' - ' + next.arrival;
      }
    }
    return route;
  }

  get flights() {
    let flights = [];
    this.route.full.flights.forEach(item => {
      flights.push(item.flight);
    });
    return flights.join(' / ');
  }
}

export class Passenger {
  constructor() {
    this.surname = null;
    this.name = null;
    this.middleName = null;
    this.title = null;
  }

  surname: string;
  name: string;
  middleName: string;
  title: string;

  get fullName(): string {
    return this.surname + ' / ' + this.name + ' / ' + this.middleName;
  }
}

export class Baggage {
  constructor(obj?) {
    if (obj) {
      obj && Object.assign(this, obj);
      this.storage.dtStart = new Date(this.storage.dtStart).getFullYear() > 2000 ? this.storage.dtStart : null;
      this.storage.dtFinish = new Date(this.storage.dtFinish).getFullYear() > 2000 ? this.storage.dtFinish : null;
      this.delivery.dt = new Date(this.delivery.dt).getFullYear() > 2000 ? this.delivery.dt : null;
      this.delivery.actualDt = new Date(this.delivery.actualDt).getFullYear() > 2000 ? this.delivery.actualDt : null;
    } else {
      this.id = null;
      this.noTag = false;
      this.tag = '';
      this.weight = {
        expected: null,
        actual: null,
      };
      this.characteristics = {
        categoryId: null,
        typeId: null,
        materialId: null,
        colorId: null,
        externalElements: [7,7],
        lockCode: '',
        description: '',
        brand: '',
        internalElements: [],
      };
      this.route = [];
      this.routeExtendsFlight = true;
      this.forwarding = {
        arrival: {
          tag: '',
          flights: [],
        },
        departure: {
          tag: '',
          flights: [],
        }
      };
      this.storage = {
        id: null,
        name: '',
        dtStart: null,
        dtFinish: null,
        note: '',
      };
      this.delivery = {
        enabled: true,
        dt: null,
        actualDt: null,
        address: '',
        note: '',
      };
      this.worldTracer = {
        typeId: null,
        type: '',
        statement: '',
        statusId: null,
        status: '',
      };
      this.damage = {
        declaredValue: null,
        note: '',
        damages: []
      };
      this.pilferage = {
        description: '',
        claim: null,
      };
      this.photo = new Array<Media>(6);
      this.attachment = new Array<Media>(2);
    }
  }
  id: string;
  noTag: boolean;
  tag: string;
  bagData: Array<BagData>;
  weight: {
    expected: number;
    actual: number;
  };
  airportId: number;
  irregularityCode1Id: number;
  irregularityCode2Id: number;
  statusId: number;
  characteristics: {
    categoryId: number;
    typeId: number;
    materialId: number;
    colorId: number;
    externalElements: Array<number>;
    lockCode: string;
    description: string;
    brand: string;
    internalElements: Array<{
      categoryId: number;
      categoryName: Array<string>;
      internals: Array<{
        id: number;
        name: Array<string>;
        description: string;
      }>;
    }>;
  };
  worldTracer: {
    typeId: number;
    type: string;
    statement: string;
    statusId: number;
    status: string;
  };
  forwarding: {
    arrival: {
      tag: string;
      flights: Array<Flight>;
    };
    departure: {
      tag: string;
      flights: Array<Flight>;
    }
  };
  delivery: {
    enabled: boolean;
    dt: Date;
    actualDt: Date;
    address: string;
    note: string;
  };
  storage: {
    id: number;
    name: string;
    dtStart: Date;
    dtFinish: Date;
    note: string;
  };
  photo: Array<Media>;
  attachment: Array<Media>;
  media: Array<Media>;
  passengerTag: {
    destinationId: number;
    destination: string;
    passenger: string;
    phone: string;
    email: string;
    address: string;
  };
  route: Array<Flight>;
  routeExtendsFlight: boolean;
  damage: {
    declaredValue: number;
    note: string;
    damages: Array<Damage>;
  };
  pilferage: {
    description: string;
    claim: number;
  };

  get fullRoute() {
    let route = '';
    let last = '';
    if (this.route && this.route.length && this.route[0]?.departure && this.route[0]?.arrival) {
      route += this.route[0].departure + ' - ' + this.route[0].arrival;
      last = this.route[0].arrival;
    }
    for (let i = 1; i < this.route.length; i++) {
      let next = this.route.find(el => el.departure === last);
      if (next && next.arrival) {
        route += ' - ' + next.arrival;
        last = next.arrival;
      }
    }
    return route;
  }

  get name(){
    return this.id || '';
  }
}

export class Damage {
  constructor() {
    this.side1 = false;
    this.side2 = false;
    this.end1 = false;
    this.end2 = false;
    this.top = false;
    this.bottom = false;
    this.levelId = null;
    this.level = null;
    this.description = null;
    this.claim = null;
    this.typeId = null;
    this.type = null;
  }

  side1: boolean;
  side2: boolean;
  end1: boolean;
  end2: boolean;
  top: boolean;
  bottom: boolean;
  levelId: number;
  level: string;
  description: string;
  claim: number;
  typeId: number;
  type: string;
}

export class Flight {
  constructor() {
    this.flight = '';
    this.dt = null;
  }
  airlineId: number;
  airline: string;
  flight: string;
  dt: Date;
  departureId: number;
  departure: string;
  arrivalId: number;
  arrival: string;
}

class Media {
  id: string;
  note: string;
}

class Route {
  constructor() {
    this.arrival = {
      airlineId: null,
      airline: null,
      flight: null,
      dt: null,
      departure: null,
      arrival: null,
      ticket: null,
      pnr: null,
    };
    this.full = {
      routeId: [],
      route: [],
      flights: [],
    };
  }
  arrival: {
    airlineId: number;
    airline: string;
    flight: string;
    dt: Date;
    departure: string;
    arrival: string;
    ticket: string;
    pnr: string;
  };
  full: {
    routeId: Array<number>;
    route: Array<string>;
    flights: Array<Flight>;
  };
}

export class CommercialAct {
  constructor(baggageId?) {
    this.baggageId = baggageId || null;
    this.comission = [];
    this.dtInsert = null;
    this.passenger = {
      passenger: null,
      phone1: null,
      phone2: null,
      passport: null,
      dateOfBirth: null,
    };
    this.route = new Route();
  }
  id: number;
  baggageId: number;
  name: string;
  dtInsert: Date;
  airportId: number;
  airlineId: number;
  reasonId: number;
  passenger: {
    passenger: string;
    phone1: string;
    phone2: string;
    passport: string;
    dateOfBirth: Date;
  };
  route: Route;
  comission: Array<Comission>;
  baggage: {
    tag: string;
    declaredValue: number;
    receipt: string;
    defectFromBaggageStatement: string;
    infoFromTag: {
      pieces: number;
      weight: number;
      departure: number;
      arrival: number;
    },
    pieces: number;
    weight: number;
    isPacked: boolean;
    isOpened: boolean;
    defect: string;
    additionalInfo: string;
    note: string;
    seal: {
      enabled: boolean;
      name: string;
    }
  };
}

export class Comission {
  position: string;
  fio: string;
}

export class Referance {
  id?: number;
  name?: Array<string>;
  baggage_statuses?: Array<Referance>;
  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }

  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }
}

export class BagData {
  Statement: {
    id: string,
    station: {
      origin: string,
      airline: string,
      handler: string,
    },
    statement: {
      name: string,
    },
    passenger: {
      name: string,
      pnr: string
    },
    baggage: {
      tag: string,
      weight: 0,
      type: string,
      material: string,
      color: string,
      elements: Array<string>,
    },
    flight: Array<Flight>;
    lastupdate: Date;
  };
  score: number;
  canSelect: boolean;
  selected: boolean;
}
