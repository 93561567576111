import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {
  ReferanceAirline,
  ReferanceAirport,
  ReferanceMovement,
  ReferanceAircraft,
  ReferanceGeoTypes,
  ReferanceSeason,
  ReferanceRegulature,
  ReferanceChannels,
} from '../reports-module-data/referance';
import { ReportParams, ReportDailyPlan, ReportSynchron, ReportMediaLogs } from './reports';
import { GlobalSettings } from '../settings/global-settings';

@Injectable({
  providedIn: 'root'
})
export class ReportsModuleRestApiService {

  constructor(private http: HttpClient,
              private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  setDefaultHttpHeader(requestId?): Object {
    // Формирование заголовков для отслеживания запросов
    // X-Correlation-ID идентификатор пользовательской сессии
    // X-Request-ID идентификатор события / запроса
    let httpOptions = {};
    httpOptions['headers'] = { 'Content-Type' : 'application/json',
                               'X-Correlation-ID' : this.globalSettings.userSessionUUID,
                               'X-Request-ID' : (requestId === undefined) ? this.globalSettings.randomUuid : requestId };
    return httpOptions;
  }

  getReportDailyPlan(paramArray: ReportParams): Observable<ReportDailyPlan> {
    let params = new HttpParams();
    if (paramArray.start != null) {
      params = params.append('start', paramArray.start);
    }
    if (paramArray.finish != null) {
      params = params.append('finish', paramArray.finish);
    }
    if (paramArray.airline != null) {
      params = params.append('airline', paramArray.airline);
    }
    if (paramArray.movement != null) {
      params = params.append('movement', paramArray.movement);
    }
    if (paramArray.geo != null) {
      params = params.append('geo', paramArray.geo);
    }
    if (paramArray.aircraft != null) {
      params = params.append('aircraft', paramArray.aircraft);
    }
    if (paramArray.airport != null) {
      params = params.append('airport', paramArray.airport);
    }
    if (paramArray.season != null) {
      params = params.append('season', paramArray.season);
    }
    if (paramArray.regularity != null) {
      params = params.append('regularity', paramArray.regularity);
    }
    console.log(params);
    console.log(this.globalSettings.apiSlotCoordinationURL + '/reports/daily_plan');
    return this.http.get<any>(this.globalSettings.apiSlotCoordinationURL + '/reports/daily_plan', { params })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Универсальный отчет
  getReport(paramArray: ReportParams): Observable<ReportDailyPlan> {
    console.log(paramArray);
    let params = new HttpParams();
    if (paramArray.start != null) {
      params = params.append('start', paramArray.start);
    }
    if (paramArray.finish != null) {
      params = params.append('finish', paramArray.finish);
    }
    if (paramArray.airline != null) {
      params = params.append('airline', paramArray.airline);
    }
    if (paramArray.movement != null) {
      params = params.append('movement', paramArray.movement);
    }
    if (paramArray.geo != null) {
      params = params.append('geo', paramArray.geo);
    }
    if (paramArray.aircraft != null) {
      params = params.append('aircraft', paramArray.aircraft);
    }
    if (paramArray.airport != null) {
      params = params.append('airport', paramArray.airport);
    }
    if (paramArray.season != null) {
      params = params.append('season', paramArray.season);
    }
    if (paramArray.regularity != null) {
      params = params.append('regularity', paramArray.regularity);
    }
    console.log(params);
    console.log(this.globalSettings.apiSlotCoordinationURL + '/reports/' + paramArray.name); // daily_plan
    return this.http.get<any>(this.globalSettings.apiSlotCoordinationURL + '/reports/' + paramArray.name, { params }) // daily_plan
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Прлучаем отчет
  getReportData(paramArray: ReportParams, url: string, port = 'apiReportURL'): Observable<any> {
    let params = new HttpParams();
    let exceptions = ['id', 'period'];
    for (const key in paramArray) {
      if (Object.prototype.hasOwnProperty.call(paramArray, key)
          && paramArray[key] !== null
          && paramArray[key] !== undefined
          && !exceptions.includes(key)
      ) {
        params = params.append(key, paramArray[key]);
      }
    }
    // if (paramArray.start != null) {
    //   params = params.append('start', paramArray.start);
    // }
    // if (paramArray.finish != null) {
    //   params = params.append('finish', paramArray.finish);
    // }
    // if (paramArray.date != null) {
    //   params = params.append('date', paramArray.date);
    // }
    // if (paramArray.export != null) {
    //   params = params.append('export', paramArray.export);
    // }
    // if (paramArray.recipients != null) {
    //   params = params.append('recipients', paramArray.recipients);
    // }
    return this.http.get<any>(this.globalSettings[port] + url, { params }) // daily_plan
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  downloadReport(paramArray: ReportParams, url: string, port = 'apiReportURL') {
    let params = new HttpParams();
    Object.keys(paramArray).forEach(item => {
      if (paramArray[item] != null) {
        params = params.set(item, paramArray[item]);
      }
    });

    return this.http.get(this.globalSettings[port] + url, { params, responseType: 'blob' })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getReportSynchron(paramArray: ReportParams): Observable<ReportSynchron[]> {
    console.log(paramArray);
    let params = new HttpParams();
    if (paramArray.start != null) {
      params = params.append('start', paramArray.start);
    }
    if (paramArray.finish != null) {
      params = params.append('finish', paramArray.finish);
    }
    if (paramArray.airline != null) {
      params = params.append('airline', paramArray.airline);
    }
    if (paramArray.movement != null) {
      params = params.append('movement', paramArray.movement);
    }
    if (paramArray.geo != null) {
      params = params.append('geo', paramArray.geo);
    }
    if (paramArray.aircraft != null) {
      params = params.append('aircraft', paramArray.aircraft);
    }
    if (paramArray.airport != null) {
      params = params.append('airport', paramArray.airport);
    }
    if (paramArray.season != null) {
      params = params.append('season', paramArray.season);
    }
    if (paramArray.regularity != null) {
      params = params.append('regularity', paramArray.regularity);
    }
    if (paramArray.full == true) {
      params = params.append('full', paramArray.regularity);
    }
    console.log(params);
    console.log(this.globalSettings.apiSlotCoordinationURL + '/reports/synchron');
    return this.http.get<any>(this.globalSettings.apiSlotCoordinationURL + '/reports/synchron', { params })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getReportMediaLogs(paramArray: ReportParams): Observable<ReportMediaLogs[]> {
    console.log(paramArray);
    let params = new HttpParams();
    if (paramArray.start != null) {
      params = params.append('start', paramArray.start);
    }
    if (paramArray.finish != null) {
      params = params.append('finish', paramArray.finish);
    }
    console.log(params);
    console.log(this.globalSettings.apiInformationalURL + '/reports/media_logs');
    return this.http.get<ReportMediaLogs[]>(this.globalSettings.apiInformationalURL + '/reports/media_logs', { params })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Movements
  getChannels(): Observable<ReferanceChannels[]> {
    return this.http.get<ReferanceChannels[]>(this.globalSettings.apiReportURL +
      '/reports/cks_stats/channels')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Movements
  getMovements(): Observable<ReferanceMovement[]> {
    return this.http.get<ReferanceMovement[]>(this.globalSettings.apiSlotCoordinationURL +
      '/movement_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Airports
  getAirports(): Observable<ReferanceAirport[]> {
    return this.http.get<ReferanceAirport[]>(this.globalSettings.apiLostFoundURL + '/master_data/airports')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Aircraft Types
  getAircraftTypes(): Observable<ReferanceAircraft[]> {
    return this.http.get<ReferanceAircraft[]>(this.globalSettings.apiSlotCoordinationURL +
      '/aircraft_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Airlines
  getAirlines(): Observable<ReferanceAirline[]> {
    return this.http.get<ReferanceAirline[]>(this.globalSettings.apiSlotCoordinationURL +
      '/airlines')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Geo Types
  getGeoTypes(): Observable<ReferanceGeoTypes[]> {
    return this.http.get<ReferanceGeoTypes[]>(this.globalSettings.apiSlotCoordinationURL +
      '/geo_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Seasons
  getSeasons(): Observable<ReferanceSeason[]> {
    return this.http.get<ReferanceSeason[]>(this.globalSettings.apiSlotCoordinationURL +
      '/seasons')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Regularities
  getRegularities(): Observable<ReferanceRegulature[]> {
    return this.http.get<ReferanceRegulature[]>(this.globalSettings.apiSlotCoordinationURL +
      '/regularities')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getReference(name, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiLostFoundURL +
                                '/master_data/' + name, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    console.log(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // Вывод ошибки временно убран
    // в текущий момент нет разделения на отдельные модули системы, от сюда
    // нет разделения на загружаемый контент что создает ошибки на площадках
    // где не работают все модули бэкенда
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
