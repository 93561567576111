<ng-sidebar-container>
  <ng-sidebar [(opened)]="sidebarOpened"
              [sidebarClass]="'app-sidebar'"
              [closeOnClickOutside]="true"
              [animate]="true">
    <div class="d-flex flex-column mx-3 h-100">
      <div class=row>
        <button type="button"
                class="btn w-100"
                (click)="toggleSidebar()">
          <i class="material-icons ml-auto">chevron_left</i>
        </button>

      </div>
      <div class="row">
        <img src="assets/img/general/no_profile_photo.png"
             class="rounded mx-auto d-block"
             width="150"
             height="150"
             alt="User photo">
      </div>
      <div class="row">
        <p class="text-center w-100">{{getUserLogin()}}</p>
      </div>
      <div class="row px-3">
        <table class="table-sm">
          <tr>
            <td class="align-top">
              <div class="font-weight-bold"
                   i18n="main|Left panel - user name@@mlp.userName">
                Name
              </div>
            </td>
            <td>
              {{getUserFirstName()}}
              <br>
              {{getUserLastName()}}
            </td>
          </tr>
          <tr>
            <td class="align-top">
              <div class="font-weight-bold"
                   i18n="main|Left panel - user position@@mlp.userPosition">
                Position
              </div>
            </td>
            <td>
              <div class="font-weight-normal"></div>
            </td>
          </tr>
          <tr>
            <td class="align-top">
              <div class="font-weight-bold"
                   i18n="main|Left panel - user company@@mlp.userCompany">
                Company
              </div>
            </td>
            <td>
              <div class="font-weight-normal"></div>
            </td>
          </tr>
        </table>
      </div>
      <hr>
      <p class="h5 w-100 text-center"
         i18n="main|Left panel - section Settings @@mlp.settingsName">
        Settings
      </p>
      <div class="row">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"
                  i18n="main|Left panel - language select menu@@mlp.languageSelect">
              Language
            </span>
          </div>
          <select class="form-control form-control-sm"
                  (change)="changeLanguage($event.target.value)">
            <option *ngFor="let language of getLanguages()"
                    value="{{language.lang}}"
                    [selected]="language.lang == getCurrentLanguage()">
              {{language.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"
                  i18n="main|Left panel - favorite module@@mlp.favoriteModule">
              Favorite module
            </span>
          </div>
          <select class="form-control form-control-sm"
                  (change)="changeLoadableModule(+$event.target.value)">
            <option *ngFor="let module of getUserModules()"
                    value="{{module.id}}"
                    [selected]="module.id == getLoadableModule()">
              {{module.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"
                  i18n="main|Left panel - favorite module@@mlp.localTimeZone">
              Local time zone
            </span>
          </div>
          <select class="form-control form-control-sm"
                  (change)="changeTimezone($event.target.value)">
            <option *ngFor="let zone of timeZones"
                    value="{{zone.name}}"
                    [selected]="zone.name == userCurrentTimezone">(UTC{{zone.offset}})&nbsp;{{zone.name}}</option>
          </select>
          <div class="input-group-append">
            <button class="btn btn-h-31 btn-outline-secondary p-0" type="button" (click)="resetTimezone()">
              <i class="material-icons m-0" data-toggle="tooltip">restart_alt</i>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column h-100 justify-content-end">
        <div class="text-center">
          <button class="btn btn-light btn-sm w-100"
                  (click)="logout()"
                  i18n="main|Left panel - logout@@mlp.logout">
            Logout
          </button>
        </div>
        <div class="text-center">
          <span class="w-100 text-center text-secondary h6">
            <span i18n="main|Left panel - version@@mlp.version">Version:</span>&nbsp;{{applicationVersion}}
          </span>
          <span *ngIf="!production && timeStamp"
                class="w-100 text-center text-secondary h6">
            @{{timeStamp}}
          </span>
          <span *ngIf="isUserTrace"
                class="w-100 rounded bg-dark text-white h6 px-2"
                i18n="main|Left panel - trace enable@@mlp.traceEnable">
            Trace enabled
          </span>
        </div>
        <div class="text-center">
        </div>
      </div>
    </div>
  </ng-sidebar>

  <!-- Page content -->
  <div ng-sidebar-content
       class="h-100">
    <nav class="navbar navbar-expand-xl navbar-dark">
      <div class="container-fluid p-0">
        <div class="navbar-brand"
             (click)="toggleSidebar()">
          <i class="material-icons align-middle"
             style="font-size: 2em;">menu</i>
        </div>
        <button class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse"
             id="navbarNavDropdown">
          <ul class="navbar-nav mr-auto">
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isSchedule">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.Schedule)]"
                 i18n="main|Top horizontal menu - section schedule@@mtm.schedule">
                Schedule
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isSlotCoordination">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.SlotCoord)]"
                 i18n="main|Top horizontal menu - section slot coordination@@mtm.slotCoordination">
                Slot coordination
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isWorkflow">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.Workflow)]"
                 i18n="main|Top horizontal menu - section workflow@@mtm.workflow">
                Workflow
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isComModule">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.ComMan), this.getComManFirstFolder()]"
                 i18n="main|Top horizontal menu - section communication module@@mtm.comModule">
                Communication module
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isMobileApron">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.MobileApron)]"
                 i18n="main|Top horizontal menu - section mobile apron@@mtm.mobileApron">
                Mobile apron
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isLogsReports">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.Reports)]"
                 i18n="main|Top horizontal menu - section logs&reports@@mtm.logReports">
                Logs&Reports
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isMasterData">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.MasterData)]"
                 i18n="main|Top horizontal menu - section master data@@mtm.masterData">
                Master data
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isBoardingControl">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.BoardingControl)]"
                 i18n="main|Top horizontal menu - section boarding control@@mtm.boardingControl">
                Boarding control
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isAdmin">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.Administration)]"
                 i18n="main|Top horizontal menu - section administration@@mtm.administration">
                Administration
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isParking">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.ParkingPlace)]"
                 i18n="main|Top horizontal menu - section parking places@@mtm.parkingPlaces">
                Parking Places
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isBalance">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.WeightBalance)]"
                 i18n="main|Top horizontal menu - section weight balance@@mtm.weightBalance">
                Weight balance
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isLostFound">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.LostFound)]"
                 i18n="main|Top horizontal menu - section weight balance@@mtm.lostAndFound">
                Lost and found
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isMaterialAccounting">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.MaterialAccounting)]"
                 i18n="main|Top horizontal menu - section weight balance@@mtm.materialAccounting">
                Material accounting
              </a>
            </li>
            <li [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}"
                class="nav-item"
                data-toggle="collapse"
                data-target="#navbarNavDropdown.show"
                *ngIf="isTest">
              <a class="nav-link"
                 [routerLink]="[getModulePath(moduleName.Test)]">
                Test
              </a>
            </li>
          </ul>

          <div class="d-flex flex-row align-items-center mr-3">
            <div *ngIf="userCurrentTime === localTimeFormat.UTC"
                 style="font-size: 0.8em;"
                 class="mr-1 text-right"><span class="text-uppercase font-weight-bold">UTC</span><br><span i18n="main|Top horizontal menu@@mtm.timeMode.utcTime">Time</span></div>
            <div *ngIf="userCurrentTime === localTimeFormat.LOC"
                 style="font-size: 0.8em;"
                 class="mr-1 text-right"><span class="text-uppercase font-weight-bold" i18n="main|Top horizontal menu@@mtm.timeMode.localTime">Local time</span><br>{{userCurrentTimezone}}
            </div>
            <div class="d-flex">
              <div class="btn-group">
                <button *ngIf="userCurrentTime === localTimeFormat.UTC"
                        class="btn btn-outline-light"
                        style="font-size: 1.4em;"
                        (click)="changeTime(localTimeFormat.LOC)">{{time | date: 'HH:mm' : '+00:00'}}</button>
                <button *ngIf="userCurrentTime === localTimeFormat.LOC"
                        class="btn btn-outline-light"
                        style="font-size: 1.4em;"
                        (click)="changeTime(localTimeFormat.UTC)">{{time | date: 'HH:mm' : userCurrentTimezoneOffset}}</button>
              </div>
            </div>
          </div>
          <div class="mr-2">
            <a [routerLink]="['/notifications']" class="notifications-icon d-block">
              <i class="material-icons text-white">notifications</i>
            </a>
          </div>
          <div><img src="{{ partnerLogo }}"
                 class="partner-logo ml-2"
                 alt="partner-logo"
                 style="display: none"
                 onload="this.style.display=''">
          </div>
        </div>
      </div>
    </nav>
    <div class="container-fluid aero-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-sidebar-container>
